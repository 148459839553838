// OBJETO GLOBAL FORM VARIABLES
const formDataChatbot = {
  nombre: '',
  telefono: '',
  correo: '',
  origenUTM: '',
  urlInicial: '',
  urlHistorial: '',
  urlOrigenUTM: '',
  reCaptchaResponse: ''
}


// INPUTS QUERY SELECTOR
const nombreChatbot = document.querySelector('#nombre-chatbot');
const correoChatbot = document.querySelector('#correo-chatbot');
const telefonoChatbot = document.querySelector('#telefono-chatbot');

const origenUTMChatbot = document.querySelector('#origenUTM-chatbot');
const urlInicialChatbot = document.querySelector('#urlInicial-chatbot');
const urlHistorialChatbot = document.querySelector('#urlHistorial-chatbot');
const urlOrigenUTMChatbot = document.querySelector('#urlOrigenUTM-chatbot');

// Form
const formularioContactoChatbot = document.querySelector("#formulario-contacto-chatbot");

// Subtmit Btn
const submitBtnChatbot = document.querySelector('#submit-form-chatbot');


const asignarFormDataChatbot = (dataVar, dataItem) => {
  dataVar.addEventListener('input', (event) => {
    const value = event.target.value;

    if(dataItem === 'nombre'){
      formDataChatbot.nombre = value;
    }
    else if(dataItem === 'correo'){
      formDataChatbot.correo = value;
    }
    else if(dataItem === 'telefono'){
      formDataChatbot.telefono = value;
    }
    else if(dataItem === 'origenUTM'){
      formDataChatbot.origenUTM = value;
    }
    else if(dataItem === 'urlInicial'){
      formDataChatbot.urlInicial = value;
    }
    else if(dataItem === 'urlHistorial'){
      formDataChatbot.urlHistorial = value;
    }
    else if(dataItem === 'urlOrigenUTM'){
      formDataChatbot.urlOrigenUTM = value;
    }
    else{
      return;
    }

    // console.log(formDataChatbot);
  });

  const asignacionDirectaChatbot = () => {
    if(dataItem === 'origenUTM'){
      formDataChatbot.origenUTM = dataVar.value;
    }
    else if(dataItem === 'urlInicial'){
      formDataChatbot.urlInicial = dataVar.value;
    }
    else if(dataItem === 'urlHistorial'){
      formDataChatbot.urlHistorial = dataVar.value;
    }
    else if(dataItem === 'urlOrigenUTM'){
      formDataChatbot.urlOrigenUTM = dataVar.value;
    }
    else if (dataItem === 'reCaptchaResponse'){
      formDataChatbot.reCaptchaResponse = dataVar.value;
    }
    else{
      return;
    }
    // console.log(formDataChatbot);
  }
  asignacionDirectaChatbot();
}

asignarFormDataChatbot(nombreChatbot, 'nombre');
asignarFormDataChatbot(correoChatbot, 'correo');
asignarFormDataChatbot(telefonoChatbot, 'telefono');
asignarFormDataChatbot(origenUTMChatbot, 'origenUTM');
asignarFormDataChatbot(urlInicialChatbot, 'urlInicial');
asignarFormDataChatbot(urlHistorialChatbot, 'urlHistorial');
asignarFormDataChatbot(urlOrigenUTMChatbot, 'urlOrigenUTM');

// Ingresa solo numeros a los inputs de Telefono 0 -> 9
const soloNumerosChatbot = (evt) => {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

const validatePathResponseChatbot = () => {
  const pathPhp = '/includes/tracking/process-form-chatbot.php';
  const pathJs = '/mensaje-enviado.php';

  sendFormChatbot(pathPhp, pathJs);
  // sendFormChatbot(pathPhp);
}

// ReCaptcha
const validateRecaptchaChatbot = () => {
  const mgsRecaptcha = document.querySelector('#msgSubmitRecaptchaChatbot');

  if (grecaptcha && grecaptcha.getResponse(0).length > 0) {
    mgsRecaptcha.textContent = '';
    formDataChatbot.reCaptchaResponse = grecaptcha.getResponse(0);
    // console.log(grecaptcha.getResponse(0));
    
    mgsRecaptcha.classList.remove('input-error');
    return true;
  } else {
    mgsRecaptcha.textContent = 'Confirma que no eres un robot';
    // console.log(grecaptcha.getResponse(0));

    mgsRecaptcha.classList.add('input-error');
    return false;
  }
}

$("#submit-form-chatbot").mouseover(function() {
  validateRecaptchaChatbot();
});

const pristineChatbot = () => {
  // window.onload = () => {
    // create the pristine instance
    let pristine = new Pristine(formularioContactoChatbot);
  
    formularioContactoChatbot.addEventListener('submit', (event) => {
      event.preventDefault();
      
      // check if the form is valid
      let valid = pristine.validate(); // returns true or false
  
      // console.log(formData);
  
      if(valid && validateRecaptchaChatbot()){
        // sendFormChatbot();
        validatePathResponseChatbot();
      }
      else{
        // console.log(valid01);
        return false;
      }
  
    });
  // };
}
pristineChatbot();


const sendFormChatbot = (pathPhp, pathJs) => {
  // mostrarCompletarCampos('', true, padreSubmitForm);
  submitBtnChatbot.classList.add('d-none');

  $("#formulario-contacto-chatbot").addClass("animacion-form");
  if($("#formulario-contacto-chatbot").hasClass("animacion-form")) {
    setTimeout(() => { 
      $("#formulario-contacto-chatbot").addClass("d-none");
      // $('#ancla-seccion-form').scrollTop(0);
      $("#procesando-datos-chatbot").removeClass("d-none");
      $("#procesando-datos-chatbot").addClass("d-flex suavizar-opacidad");
    }, 700);
  }
  else{
    $("#formulario-contacto-chatbot").removeClass("animacion-form");
    $("#formulario-contacto-chatbot").removeClass("d-none");
  }

  // postData(formDataChatbot, pathPhp, pathJs);
  postDataChatbot(formDataChatbot, pathPhp, pathJs);
} 

// async function postData(formattedformDataChatbot, pathPhp, pathJs){
async function postDataChatbot(formattedformDataChatbot, pathPhp, pathJs){
  const response = await fetch(pathPhp, {
    method: 'POST',
    body: JSON.stringify(formattedformDataChatbot)
  });

  const data = await response.text();
  console.log(data);
  // const data = await response.json();

  //This should later print out the values submitted through the form
  // console.log(data);
  if (data === "Completado") {
    formularioContactoChatbot.reset();
    window.location.href = pathJs;
  } else {
    console.log('Algo salio muy mal :(');
  }
}
